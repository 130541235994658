import type { FormHeadingTranslationObj } from "../../translation-keys/form-heading-text-keys.js";

const GenericHeading = "이 양식을 작성하여 지원 리소스를 다운로드하십시오.";

const FormHeading: FormHeadingTranslationObj={
  "document-Heading": GenericHeading,
  "document-landing-page-Heading": GenericHeading,
  "post-event-assets-Heading": "",
  "ppc-Heading": GenericHeading,
  "case-study-Heading": GenericHeading,
  "video-Heading": GenericHeading,
  "video-landing-page-Heading": GenericHeading,
  "events-registration-Heading": "Register by filling out the form below.",
  "resource-forms-Heading": GenericHeading,
  "contact-Heading": "Tell us a little about yourself so we can answer your software, quality and compliance questions."
};

export default FormHeading;